// return the user data from the session storage
export const getUser = () => {
  const userStr = sessionStorage.getItem('user')
  if (userStr) return JSON.parse(userStr)
  else return null
}

// return the token from the session storage
export const getToken = () => {
  return sessionStorage.getItem('token') || null
}

// return the id from the session storage
export const getId = () => {
  let data = JSON.parse(sessionStorage.getItem('data')) || null

  if(!data) {
    return null
  }

  return data.id || null
}

// return the email from the session storage
export const getEmail = () => {
  let data = JSON.parse(sessionStorage.getItem('data')) || null

  if(!data) {
    return null
  }

  return data.email || null
}

// return the roles from the session storage
export const getRoles = () => {
  let data = JSON.parse(sessionStorage.getItem('data')) || null

  if(!data) {
    return null
  }

  return data.roles || null
}

// remove the token and user from the session storage
export const removeUserSession = () => {
  sessionStorage.removeItem('token')
  sessionStorage.removeItem('user')
  sessionStorage.removeItem('data')
}

// set the token and user from the session storage
export const setUserSession = (token, user, data) => {
  sessionStorage.setItem('token', token)
  sessionStorage.setItem('user', JSON.stringify(user))
  sessionStorage.setItem('data', JSON.stringify(data))
}
