import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import * as qs from 'query-string'
import Footer from '../Common/Footer'
import Header from '../Common/Header'
import './Instructions.css'

class Instructions extends Component {
  componentDidMount = () => {
    document.title = 'Seguridad Social - Instrucciones'
    document.body.classList.add('instructions')
  }

  componentWillUnmount = () => {
    document.title = 'Seguridad Social'
    document.body.classList.remove('instructions')
  }

  render() {
    const auth = qs.parse(window.location.search).auth
    return (
      <>
        {/* Header Welcome */}
        <Header />
        {/* Container */}
        <div className='container'>
          <div className='instructions-content'>
            <div className='instructions-content__title'>
              <h1 className='title'>¿Cómo eres de digital?</h1>
            </div>
            <div className='instructions-content__text'>
              <p className='text'>
                Los conocimientos y habilidades necesarios en el mundo digital
                en el que vivimos son muy diferentes a las que se necesitaban
                años atrás. La tecnología ha invadido y reordenado nuestra vida,
                en un presente dinámico y cambiante, como hemos visto a raíz de
                la situación de pandemia por el COVID-19. La Unión Europea ha
                definido un conjunto de habilidades digitales para afrontar
                mejor este futuro (Marco DIGCOMP). En base a estas nuevas
                competencias digitales hemos desarrollado este cuestionario que
                esperamos te ayude a descubrir tus hábitos y comportamientos en
                relación al uso de medios digitales, tanto en el trabajo como en
                tu vida personal.
              </p>
            </div>
            <div className='instructions-content__text'>
              <p className='text'>
                El cuestionario tiene un total de 35 preguntas, con un tiempo
                estimado para contestarlo de unos 10 minutos. No hay respuestas
                buenas o malas, ya que una conducta digital puede ser más
                frecuente en unas personas y menos frecuente en otras. Trata de
                contestar a todas las cuestiones, sin dejar ninguna en blanco.
                Al terminar recibirás un informe personalizado y privado.
              </p>
            </div>
            <div className='instructions-content__text-bold'>
              <p className='text-bold'>Esperamos que te sea de utilidad.</p>
            </div>
            <div className='instructions-content__text-featured'>
              <p className='text-featured'>Gracias por tu colaboración.</p>
            </div>
          </div>
          <div className="button__wrapper">
            <div className='instructions-content__button'>
                <div className='button'>
                <Link className='button__link' to={`/juego?auth=${auth}`}>
                  ¿Empezamos? ‣
                </Link>
                </div>
              </div>
          </div>
        </div>
        {/* Footer */}
        <Footer />
      </>
    )
  }
}

export default Instructions
