import React, { Component } from 'react'
import MaterialTable from 'material-table'
import './List.css'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { getToken } from './../../../Utils/Common'
import { columns } from './columns'
import { formatData } from './utils'
import { deleteModal, successModal } from '../../Common/Modal'

class QuestionList extends Component {
  state = {
    questions: [],
    columns: [],
    removeId: null,
    openDialog: false,
  }

  handleEditLink = (data) => {
    const id = data ? data.id : uuidv4()
    this.props.history.push(`/admin/questions/${id}`)
  }

  handleShowDialog = (dataId) => {
    this.setState({
      removeId: dataId,
      openDialog: true,
    })
  }

  handleCloseDialog = () => {
    this.setState({
      openDialog: false,
    })
  }

  removeData = () => {
    const config = {
      headers: { Authorization: `Bearer ${getToken()}` },
    }
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/api/questions/${this.state.removeId}`,
        config
      )
      .then((response) => {
        this.setState({
          questions: this.state.questions.filter(
            (exercise) => exercise.id !== this.state.removeId
          ),
          removeId: null,
        })
        successModal({
          title: 'Eliminado!',
          text: 'El registro ha sido eliminado con éxito.',
        })
      })
      .catch((error) => {
        error.response
          ? console.log(error.response.data.message)
          : console.log('error', error)
      })

    this.setState({
      openDialog: false,
    })
  }

  componentDidMount() {
    const config = {
      headers: { Authorization: `Bearer ${getToken()}` },
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/questions`, config)
      .then((response) => {
        if (response.data.length > 0) {
          this.setState({ questions: formatData(response.data), columns })
        }
      })
      .catch((error) => {
        error.response
          ? console.log(error.response.data.message)
          : console.log('error', error)
      })
  }

  render() {
    return (
      <main className='content-table'>
        {this.state.openDialog && deleteModal({ callback: this.removeData })}
        <div className='form-header'>
          <div className='icon-section'>
            <i className='fas fa-question'></i>
          </div>
          <div className='title-section'>
            <p className='title-section__title'>{this.props.name}</p>
            <small className='title-section__description'>
              Listado completo de preguntas
            </small>
          </div>
        </div>
        <div style={{ maxWidth: '100%' }}>
          <MaterialTable
            title={null}
            columns={this.state.columns}
            data={this.state.questions}
            actions={[
              {
                icon: 'edit',
                tooltip: 'Edit',
                onClick: (event, rowData) => this.handleEditLink(rowData),
              },
              {
                icon: 'add',
                isFreeAction: true,
                onClick: (event) => this.handleEditLink(null),
              },
              (rowData) => ({
                icon: 'delete',
                tooltip: 'Delete',
                onClick: (event, rowData) => this.handleShowDialog(rowData.id),
              }),
            ]}
            options={{
              actionsColumnIndex: -1,
              exportButton: false,
              search: false,
              filtering: true,
            }}
          />
        </div>
      </main>
    )
  }
}

export default QuestionList
