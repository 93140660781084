export const columns = [
  {
    title: 'Posición',
    field: 'position',
    filtering: false,
  },
  {
    title: 'Competencia',
    field: 'competence',
    lookup: {
      'análisis y tratamiento de datos': 'análisis y tratamiento de datos',
      'resolución de problemas': 'resolución de problemas',
      'comunicación y colaboración': 'comunicación y colaboración',
      'creación de contenidos digitales': 'creación de contenidos digitales',
      seguridad: 'seguridad',
    },
  },
  {
    title: 'Contenido',
    field: 'content',
  },
  {
    title: 'Tipo',
    field: 'answerType',
    lookup: { única: 'única', múltiple: 'múltiple' },
  },
]
