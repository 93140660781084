import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import * as qs from 'query-string'
import './RestorePassword.css'
import logo from '../../../assets/img/logo-proyecta-tu-talento.svg'
import Input from '../../Admin/Common/FormComponents/Input'

class RestorePassword extends Component {
  state = {
    error: null,
    success: null,
    email: qs.parse(window.location.search).email ?? '',
    token: qs.parse(window.location.search).token ?? '',
    password: '',
  }

  componentDidMount = () => {
    document.title = 'Seguridad Social - Restaurar Contraseña'
    document.body.classList.add('security')
  }

  componentWillUnmount = () => {
    document.title = 'Seguridad Social'
    document.body.classList.remove('security')
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const data = {
      password: this.state.password,
      email: this.state.email,
      token: this.state.token,
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/api/users/reset-password`, data)
      .then((response) => {
        this.setState({
          error: null,
          success:
            'La contraseña se ha restablecido correctamente. Ahora ya puedes acceder a nuestra plataforma.',
        })
        // this.props.history.push('/login');
      })
      .catch((error) => {
        if (error.response.status === 400) {
          this.setState({
            success: null,
            error:
              'Parece que el token ha expirado. Deberás volver a generar uno nuevo.',
          })
        } else {
          this.setState({
            success: null,
            error: 'Algo malo ha ocurrido. Inténtalo más tarde.',
          })
        }
      })
  }

  onChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    this.setState({ [name]: value })
  }

  render() {
    return (
      <form className='login-form' action='#' method='POST'>
        <div className='login-form__logo-container'>
          <img className='login-form__logo' src={logo} alt='talento-digital' />
        </div>
        <div className='login-form__content'>
          <div className='login-form__header'>Restrablecer contraseña</div>
          <Input
            className='login-form__input'
            type='text'
            name='password'
            placeholder='Password'
            autoFocus
            value={this.state.password}
            onChange={(e) => this.onChange(e)}
          />
          <button
            className='login-form__button button__link'
            type='submit'
            onClick={this.handleSubmit}
          >
            Cambiar ‣
          </button>
          {this.state.error && (
            <div className='login-form__feedback'>
              <small style={{ color: 'red' }}>{this.state.error}</small>
              <br />
            </div>
          )}
          <br />
          {this.state.success && (
            <div className='login-form__feedback'>
              <small style={{ color: 'green' }}>{this.state.success}</small>
              <br />
            </div>
          )}
          <br />
          <div className='login-form__links'>
            <Link className='login-form__link' to='login'>
              Volver
            </Link>
          </div>
        </div>
      </form>
    )
  }
}

export default RestorePassword
