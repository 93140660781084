import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import * as qs from 'query-string'
import Footer from '../Common/Footer'
import Header from '../Common/Header'
import './Arquetipo.css'
import avatar from './../../../assets/img/arquetipo-creador-digital.svg'

class Arquetipo extends Component {
  state = {
    response: null,
  }

  formatImageUrl = (path) => {
    return `${process.env.REACT_APP_API_URL}/${
      path.split('public').reverse()[0]
    }`
  }

  componentDidMount = () => {
    document.title = 'Seguridad Social - Arquetipo'
    document.body.classList.add('arquetipo')

    const auth = qs.parse(window.location.search).auth
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/game/${auth}`)
      .then((response) => {
        if (response.data.finished !== true) {
          this.props.history.push(`/game?auth=${auth}`)
        }
        this.setState({ response: response.data })
        document.body.style.backgroundImage = `url(${this.formatImageUrl(
          response.data.arquetipo.imageBackground
        )})`
      })
      .catch((error) => {
        this.props.history.push('/')
      })
  }

  componentWillUnmount = () => {
    document.title = 'Seguridad Social'
    document.body.classList.remove('arquetipo')
    document.body.style.backgroundImage = null
  }

  capitalize = (s) => {
    if (typeof s !== 'string') return ''
    let response = s.toLowerCase()
    return response.charAt(0).toUpperCase() + response.slice(1)
  }

  render() {
    return (
      <>
        <Header />
        <div className='container'>
          <div className='arquetipo-title'>
            <h3 className='title'>¡Enhorabuena!<br />Este es tu Arquetipo Digital según la competencia que mejor dominas</h3>
          </div>
          <div className='arquetipo-content'>
            <div className='arquetipo__content'>
              <div className='arquetipo__avatar'>
                <img
                  src={
                    this.state.response && this.state.response.arquetipo.image
                      ? this.formatImageUrl(this.state.response.arquetipo.image)
                      : avatar
                  }
                  alt={this.state.response ? this.state.response.arquetipo.name : ''}
                />
              </div>
              <div className='arquetipo__title'>
                <h1 className='title'>{this.state.response ? this.state.response.arquetipo.name : null}</h1>
              </div>
              <div className='arquetipo__text'>
                <p className='text-featured'>
                  {this.state.response ? this.state.response.arquetipo.shortDescription : null}
                </p>
              </div>
              <div className='arquetipo__text'>
                <p className='text'>
                  {this.state.response ? this.state.response.arquetipo.description : null}
                </p>
              </div>
            </div>
          </div>
          <div className="button__container">
            <div className='arquetipo__feedback'>
              <p className='feedback__text'>
              Haz clic en el enlace y accede a los contenidos de aquellas competencias donde tienes menor nivel para que puedas seguir formándote y avanzando en la carrera digital.
              </p>
            </div>
            <div className='question__button'>
              <div className='button'>
                <a className='button__link' href={`${process.env.REACT_APP_EXTERNAL_LINK}`}>
                  Sigue avanzando ‣
                </a>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}

export default Arquetipo
