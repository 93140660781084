import React from 'react'
import { getUser, removeUserSession } from '../../../Utils/Common'
import avatar from '../../../../assets/img/avatar.jpg'
import './Navbar.css'

export default function Navbar(props) {
  const [open, setOpen] = React.useState(false)

  const handleRemoveSession = (e) => {
    e.preventDefault()
    removeUserSession()
    props.history.push('/login')
  }

  const handleRedirectToProfilePage = (e) => {
    e.preventDefault()
    setOpen(false)
    props.history.push('/admin/profile')
  }

  const handleDropdownMenu = (e) => {
    e.preventDefault()
    if (open) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }

  const userName = getUser() ?? 'admin@admin.com'

  return (
    <nav className='header-navbar'>
      <div className='user-navbar'>
        <a href='/' id='dropdown-menu' onClick={handleDropdownMenu}>
          <div className='user-navbar__email'>{userName}</div>
          <div className='user-navbar__avatar'>
            <img className='user-navbar__image' src={avatar} alt={userName} />
          </div>
        </a>
        {open && (
          <div className='dropdown-menu'>
            <a
              href='/'
              className='dropdown__item'
              onClick={handleRedirectToProfilePage}
            >
              <i className='fas fa-user'></i> Perfil
            </a>
            <div className='dropdown__divider'></div>
            <a
              href='/'
              className='dropdown__item'
              onClick={handleRemoveSession}
            >
              <i className='fas fa-power-off'></i> Salir
            </a>
          </div>
        )}
      </div>
    </nav>
  )
}
