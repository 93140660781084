import React from 'react'

const Input = (props) => {
  const { name, label, type, touched, valid, error, ...rest } = props

  let elementClass = 'form__input'
  if (touched && !valid) {
    elementClass = 'form__input form__error'
  }

  return (
    <div className='form__group'>
      {label && <label htmlFor={name}>{label}</label>}
      <input
        type={type ?? 'text'}
        className={elementClass}
        name={name}
        {...rest}
      />
      {touched && !valid && (
        <div className='form__feedback'>
          <small style={{ color: 'red' }}>{error}</small>
          <br />
        </div>
      )}
    </div>
  )
}

export default Input
