import React, { Component } from 'react'
import axios from 'axios'
import { getToken } from '../../Utils/Common'
import './Dashboard.css'
import { emptyCSV } from './emptyCSV'

export default class Dashboard extends Component {
  state = {
    error: false
  }
  componentDidMount = () => {
    document.title = 'Seguridad Social - Dashboard'
  }

  componentWillUnmount = () => {
    document.title = 'Seguridad Social'
  }

  convertTimestamp = (timestamp) => {
    let unix_timestamp = timestamp
    const date = new Date(unix_timestamp * 1000)
    const day = "0" + date.getDay()
    const month = "0" + (date.getMonth() + 1)
    const year = date.getFullYear()
    const hours = date.getHours();
    const minutes = "0" + date.getMinutes()
    const seconds = "0" + date.getSeconds()
    
    var formattedTime = day.substr(-2) + '-' + month.substr(-2) + '-' + year + ' ' + hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2)
    
    return formattedTime
  }

  convertResponseJSONToCSV = (responseJSON) => {
    const completeData = responseJSON.map(el => {
      let elMod = emptyCSV
      Object.keys(el).map(key => {
        switch(key) {
          case 'queryParam':
            elMod['1. auth'] = el[key]
            break;
          case 'arquetipo':  
            elMod['2. arquetipo'] = el[key]
            break;
          case 'createdAt':
            elMod['3. creado'] = this.convertTimestamp(el[key])
            break;  
          case 'data':
            Object.keys(el[key]).map(dataKey => {
              elMod[`4.${dataKey}. pregunta ${dataKey}`] = el[key][dataKey][0].points

              return null
            })
            break;
          case 'blocks':
            Object.keys(el[key]).map((competenceTitle, index) => {
              elMod[`5. ${competenceTitle.toLowerCase()}`] = el[key][competenceTitle].name

              return null
            })
            break;  
          default:
            break;  
        }

        return null
      })
      return elMod
    })

    const data = completeData.map(rowObject => Object.values(rowObject))
    return data
  } 

  getHeaderCSV = () => {
    const head = []
    Object.keys(emptyCSV).map(key => head.push(key))

    return head
  }

  getDataCSV = (responseJSON) => {
    const headerCSV = this.getHeaderCSV()
    const dataRowsCSV = this.convertResponseJSONToCSV(responseJSON)
    let csvContent = [[...headerCSV], ...dataRowsCSV]
    let csvData = '';
    for (var i = 0; i < csvContent.length; i++) {
        var value = csvContent[i]
        for (var j = 0; j < value.length; j++) {
            var innerValue =  value[j]===null?'':value[j].toString()
            var result = innerValue.replace(/"/g, '""')
            if (result.search(/("|,|\n)/g) >= 0)
                result = '"' + result + '"'
            if (j > 0)
            csvData += ','
            csvData += result
        }
        csvData += '\n'
    }

    return csvData
  }

  handleDownloadCSV = (responseJSON) => {
    const target = document.createElement('a')
    const blob = new Blob([this.getDataCSV(responseJSON)],{type: 'text/csv;charset=utf-8;'});
    const url = URL.createObjectURL(blob);
    target.href = url
    target.setAttribute('download', 'data.csv');
    target.click()
  }

  handleExport = (e) => {
    const token = getToken();
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/results?finished=false`, config)
      .then((response) => {
        this.handleDownloadCSV(response.data)
      })
      .catch((error) => {
        this.setState({ error: 'Parece que ha habido un error al exportar. Inténtalo más tarde.' })
      })
  }

  render() {
    return (
      <section className='dashboard-export'>
        <div className='card'>
          <div className='icon-section' onClick={this.handleExport}>
            <i className='fas fa-download'></i>
          </div>
          <div className='title-section'>
            <p className='title-section__title'>Exportar</p>
            <small className='title-section__description'>
              Descargar datos de los resultados
            </small>
            {this.state.error && (
              <small style={{ color: 'red' }}>{this.state.error}</small>
            )}
          </div>
        </div>
      </section>
    )
  }
}
