import Swal from 'sweetalert2'
import './Modal.css'

export function successModal({
  title = 'Acción realizada con éxito',
  text = '',
}) {
  Swal.fire({
    position: 'top-end',
    icon: 'success',
    title,
    text,
    showConfirmButton: false,
    timer: 1500,
  })
}

export function deleteModal({
  title = 'Seguro que quieres eliminar el registro?',
  text = 'Debes saber que está acción no se puede deshacer.',
  confirmButtonColor = '#F99122',
  cancelButtonColor = '#d33',
  confirmButtonText = 'Eliminar',
  callback,
}) {
  Swal.fire({
    title,
    text,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor,
    cancelButtonColor,
    confirmButtonText,
  }).then((result) => {
    if (result.value) {
      callback()
    }
  })
}
