import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import * as qs from 'query-string'

// handle the private routes
function FrontendPrivateRoute({ component: Component, ...rest }) {
  const auth = qs.parse(window.location.search).auth

  return (
    <Route
      {...rest}
      render={(props) =>
        auth ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/not-found' }}
          />
        )
      }
    />
  )
}

export default FrontendPrivateRoute
