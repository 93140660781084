import React, { Component } from 'react'
import './Header.css'
import logo from '../../../../assets/img/logo-proyecta-tu-talento.svg'

class Header extends Component {
  render() {
    return (
      <header className='header-welcome'>
        <img src={logo} alt='proyecta tu talento digital' />
      </header>
    )
  }
}

export default Header
