const validate = (value, rules) => {
  let isValid = true

  for (let rule in rules) {
    switch (rule) {
      case 'required':
        isValid = isValid && required(value)
        break
      case 'minValue':
        isValid = isValid && minValueValidator(value, rules[rule])
        break
      case 'maxValue':
        isValid = isValid && maxValueValidator(value, rules[rule])
        break
      case 'numeric':
        isValid = isValid && numericValidator(value)
        break
      case 'email':
        isValid = isValid && isEmail(value)
        break
      case 'enum':
        isValid = isValid && enumValidator(value, rules[rule])
        break
      default:
        isValid = true
        break
    }
  }
  return isValid
}

const required = (value) => {
  return value.trim() !== ''
}

const minValueValidator = (value, minValue) => {
  return Number(value) >= minValue
}

const maxValueValidator = (value, maxValue) => {
  return Number(value) <= maxValue
}

const numericValidator = (value) => {
  return !isNaN(value)
}

const enumValidator = (value, values) => {
  return values.includes(value)
}

const isEmail = (value) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(value).toLowerCase())
}

export default validate
