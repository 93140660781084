import React from 'react'
import Sidebar from './Common/Sidebar'
import MainContent from './Common/MainContent'

export default function Admin(props) {
  return (
    <div className={props.classname}>
      <Sidebar {...props} />
      <MainContent component={props.component} {...props} />
    </div>
  )
}
