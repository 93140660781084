import React from 'react'
import SidebarList from '../SidebarList'
import './Sidebar.css'
import logo from '../../../../assets/img/logo-proyecta-tu-talento.svg'

export default function Sidebar(props) {
  return (
    <aside className='sidebar'>
      <div className='logo-container'>
        <img className='logo' src={logo} alt='talento-digital' />
      </div>
      <div className='menu-container'>
        <SidebarList {...props} />
      </div>
    </aside>
  )
} 
