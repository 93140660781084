export const emptyCSV = {
  "1. auth": "",
  "2. arquetipo": "",
  "3. creado": "",
  "4.1. pregunta 1": "",
  "4.2. pregunta 2": "",
  "4.3. pregunta 3": "",
  "4.4. pregunta 4": "",
  "4.5. pregunta 5": "",
  "4.6. pregunta 6": "",
  "4.7. pregunta 7": "",
  "4.8. pregunta 8": "",
  "4.9. pregunta 9": "",
  "4.10. pregunta 10": "",
  "4.11. pregunta 11": "",
  "4.12. pregunta 12": "",
  "4.13. pregunta 13": "",
  "4.14. pregunta 14": "",
  "4.15. pregunta 15": "",
  "4.16. pregunta 16": "",
  "4.17. pregunta 17": "",
  "4.18. pregunta 18": "",
  "4.19. pregunta 19": "",
  "4.20. pregunta 20": "",
  "4.21. pregunta 21": "",
  "4.22. pregunta 22": "",
  "4.23. pregunta 23": "",
  "4.24. pregunta 24": "",
  "4.25. pregunta 25": "",
  "4.26. pregunta 26": "",
  "4.27. pregunta 27": "",
  "4.28. pregunta 28": "",
  "4.29. pregunta 29": "",
  "4.30. pregunta 30": "",
  "4.31. pregunta 31": "",
  "4.32. pregunta 32": "",
  "4.33. pregunta 33": "",
  "4.34. pregunta 34": "",
  "4.35. pregunta 35": "",
  "4.36. pregunta 36": "",
  "5. resolución de problemas": "",
  "5. análisis y tratamiento de datos": "",
  "5. comunicación y colaboración": "",
  "5. seguridad": "",
  "5. creación de contenidos digitales": ""
}
