import React, { Component } from 'react'
import axios from 'axios'
import { getUser, getId, getRoles, getToken } from '../../Utils/Common'
import './Profile.css'
import Input from '../Common/FormComponents/Input'

export default class Dashboard extends Component {
  state = {
    success: null,
    error: null,
    id: getId(),
    email: getUser(),
    password: '',
    roles: getRoles(),
    token: getToken(),
  }

  componentDidMount = () => {
    document.title = 'Seguridad Social - Perfil'
  }

  componentWillUnmount = () => {
    document.title = 'Seguridad Social'
  }

  handleSubmit = (e) => {
    e.preventDefault()

    if (!this.state.password) {
      this.setState({ error: 'El campo password es requerido.' })
      return
    }

    const data = {
      id: this.state.id,
      email: this.state.email,
      password: this.state.password,
      roles: this.state.roles,
    }

    const config = {
      headers: { Authorization: `Bearer ${this.state.token}` },
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/users/${this.state.id}`,
        data,
        config
      )
      .then((response) => {
        this.setState({
          error: null,
          success: 'La contraseña se ha modificado correctamente.',
          password: '',
        })
      })
      .catch((error) => {
        if (error.response.status === 400) {
          this.setState({
            success: null,
            error:
              'Parece que el token ha expirado. Deberás volver a generar uno nuevo.',
          })
        } else {
          this.setState({
            success: null,
            error: 'Algo malo ha ocurrido. Inténtalo más tarde.',
          })
        }
      })
  }

  onChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    this.setState({ [name]: value })
  }

  render() {
    return (
      <section className='dashboard-form'>
        <div className='form-profile-header'>
          <div className='icon-section'>
            <i className='far fa-user'></i>
          </div>
          <div className='title-section'>
            <p className='title-section__title'>Perfil</p>
            <small className='title-section__description'>
              Cambiar contraseña
            </small>
          </div>
        </div>
        <form className='reset-password-form' action='#' method='POST'>
          <div className='reset-password-form__content'>
            <Input
              className='form__input'
              type='password'
              name='password'
              placeholder='Password'
              autoFocus
              value={this.state.password}
              onChange={(e) => this.onChange(e)}
            />
            <button
              className='form__button button__link'
              type='submit'
              onClick={this.handleSubmit}
            >
              Cambiar Contraseña ‣
            </button>
            {this.state.error && (
              <div className='login-form__feedback'>
                <small style={{ color: 'red' }}>{this.state.error}</small>
                <br />
              </div>
            )}
            <br />
            {this.state.success && (
              <div className='login-form__feedback'>
                <small style={{ color: 'green' }}>{this.state.success}</small>
                <br />
              </div>
            )}
          </div>
        </form>
      </section>
    )
  }
}
