export function formatData(data) {
  const dataFormatted = data.map((row) => {
    const dataFormattedRow = {}
    Object.keys(row).map((rowKey) => {
      switch (rowKey) {
        case 'answerType':
          dataFormattedRow[rowKey] =
            row[rowKey] === 'UNIQUE' ? 'única' : 'múltiple'
          break
        case 'competence':
          dataFormattedRow[rowKey] = row[rowKey].toLowerCase()
          break
        case 'position':
        case 'content':
        case 'id':
          dataFormattedRow[rowKey] = row[rowKey]
          break
        default:
          break
      }

      return null
    })

    return dataFormattedRow
  })

  return dataFormatted
}
