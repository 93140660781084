import React from 'react'

const Select = (props) => {
  const {
    name,
    label,
    options,
    defaultValue,
    touched,
    valid,
    error,
    ...rest
  } = props

  let elementClass = 'form__select'
  if (touched && !valid) {
    elementClass = 'form__select form__error'
  }

  return (
    <div className='form__group'>
      {label && <label htmlFor={name}>{label}</label>}
      <select className={elementClass} name={name} {...rest}>
        {defaultValue && <option value=''>{defaultValue}</option>}
        {options.map((option, i) => (
          <option key={i} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {touched && !valid && (
        <div className='form__feedback'>
          <small style={{ color: 'red' }}>{error}</small>
          <br />
        </div>
      )}
    </div>
  )
}

export default Select
