export const levelCompetences = [
  'NOVEL DIGITAL',
  'EXPLORADOR DIGITAL',
  'AVANZADO DIGITAL',
  'EXPERTO DIGITAL',
];

export const levelCompetencesData = {
  'NOVEL DIGITAL': {
    min: 7,
    max: 11,
  },
  'EXPLORADOR DIGITAL': {
    min: 12,
    max: 18,
  },
  'AVANZADO DIGITAL': {
    min: 19,
    max: 24
  },
  'EXPERTO DIGITAL': {
    min: 25,
    max: 28
  },
}
