import React from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import FrontendPublicRoute from './Components/Utils/RoutesComponent/FrontendPublicRoute'
import FrontendPrivateRoute from './Components/Utils/RoutesComponent/FrontendPrivateRoute'
import AdminPrivateRoute from './Components/Utils/RoutesComponent/AdminPrivateRoute'
import NotFound from './Components/Frontend/NotFound'
import Login from './Components/Frontend/Login'
import ForgotPassword from './Components/Frontend/ForgotPassword'
import RestorePassword from './Components/Frontend/RestorePassword'
import Welcome from './Components/Frontend/Welcome'
import Legal from './Components/Frontend/Legal'
import Instructions from './Components/Frontend/Instructions'
import Game from './Components/Frontend/Game'
import Arquetipo from './Components/Frontend/Arquetipo'
import Resultado from './Components/Frontend/Resultado'
import Dashboard from './Components/Admin/Dashboard'
import Profile from './Components/Admin/Profile'
import QuestionList from './Components/Admin/Question/List'
import QuestionForm from './Components/Admin/Question/Form'

function App() {
  return (
    <BrowserRouter>
      <div>
        <Switch>
          <AdminPrivateRoute
            exact
            path='/admin/dashboard'
            component={Dashboard}
            name={'Dashboard'}
            classname={'dashboard'}
          />
          <AdminPrivateRoute
            exact
            path='/admin/profile'
            component={Profile}
            name={'Perfil'}
            classname={'dashboard'}
          />
          <AdminPrivateRoute
            exact
            path='/admin/questions'
            component={QuestionList}
            name={'Preguntas'}
            classname={'dashboard'}
          />
          <AdminPrivateRoute
            exact
            path='/admin/questions/:id'
            component={QuestionForm}
            name={'Formulario Preguntas'}
            classname={'dashboard'}
          />
          <FrontendPrivateRoute exact path='/' component={Welcome} />
          <FrontendPrivateRoute exact path='/aviso-legal' component={Legal} />
          <FrontendPrivateRoute
            exact
            path='/instrucciones'
            component={Instructions}
          />
          <FrontendPrivateRoute exact path='/juego' component={Game} />
          <FrontendPrivateRoute exact path='/resultado' component={Resultado} />
          <FrontendPrivateRoute exact path='/arquetipo' component={Arquetipo} />
          <FrontendPublicRoute exact path='/login' component={Login} />
          <FrontendPublicRoute
            exact
            path='/forgot-password'
            component={ForgotPassword}
          />
          <FrontendPublicRoute
            exact
            path='/restore-password'
            component={RestorePassword}
          />
          <FrontendPublicRoute component={NotFound} />
        </Switch>
      </div>
    </BrowserRouter>
  )
}

export default App
