import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { getToken } from '../Common'

// handle the public routes
function PublicRoute({ component: Component, ...rest }) {
 
  return (
    <Route
      {...rest}
      render={(props) =>
        !getToken() ? (
          <Component {...props} name={rest.name} />
        ) : (
          <Redirect to={{ pathname: '/' }} />
        )
      }
    />
  )
}

export default PublicRoute
