import React, { Component } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import * as qs from 'query-string'
import Footer from '../Common/Footer'
import Header from '../Common/Header'
import { competences as presentationOrder } from './competences';
import Graph from './Graph';
import './Resultado.css'

class Resultado extends Component {
  state = {
    response: null,
  }

  componentDidMount = () => {
    document.title = 'Seguridad Social - Resultado'
    document.body.classList.add('result')

    const auth = qs.parse(window.location.search).auth
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/game/${auth}`)
      .then((response) => {
        console.log('response', response);
        this.setState({ response: response.data })
      })
      .catch((error) => {
        this.props.history.push('/')
      })
  }

  componentWillUnmount = () => {
    document.title = 'Seguridad Social'
    document.body.classList.remove('result')
  }

  capitalize = (s) => {
    if (typeof s !== 'string') return ''
    let response = s.toLowerCase()
    return response.charAt(0).toUpperCase() + response.slice(1)
  }

  calculatePercentage = (value) => {
    return (value * 100).toFixed(0);
  }

  competencesOrdered = (competences = {}) => {
    let competencesOrdered = [];
    presentationOrder.map(competenceKey => {
      competencesOrdered.push({ ...competences[competenceKey], name: competenceKey});
    })
    
    return competencesOrdered;
  }

  formattedString = (string) => string.toLowerCase().replace(' ', '_');
  
  getIndex = (value) => value.toString().padStart(2, '0');

  render() {
    const globalCompetence = this.state.response ? this.state.response.globalCompetence : null;
    const arquetipo = this.state.response ? this.state.response.arquetipo : null;
    const auth = qs.parse(window.location.search).auth

    return (
      <>
        <Header />
        <div className='container'>
          <div className='result-content'>
            <div className='result__content'>
              <div className='result__avatar'>
                  <h3 className='title'>¡Fantástico!<br />Este es tu perfil global</h3>
              </div>
              <div className='result__title'>
                <h1 className={`title ${globalCompetence ? this.formattedString(globalCompetence.level) : null}`}>{globalCompetence ? globalCompetence.level : null}</h1>
                <p className='subtitle'>Te encuentras en un <span className="featured">{globalCompetence ? this.calculatePercentage(globalCompetence.percentage) : 0}%</span> de la carrera digital</p>
              </div>
              <div className='result__text'>
                <p className='text'>
                  {globalCompetence ? globalCompetence.description : null}
                </p>
              </div>
            </div>
            <div className="result__statistics">
              <div className='result__statistics__title'>
                  <h3 className='statistics__title'>Competencias<br />Digitales</h3>
              </div>
              {arquetipo && this.competencesOrdered(arquetipo.competences).map(
                ({level, name}, index) => {
                  return (
                    <div key={name} className='competence__statistics'>
                      <div className='property'>
                        <p className='property__title'>
                          <span className="property__index">{this.getIndex(index + 1)}</span> {this.capitalize(name)}
                        </p>
                      </div>
                      <div className={`level ${level ? this.formattedString(level) : null}`}>
                        <p className='level__value'>{level}</p>
                      </div>
                    </div>
                  )
                }
              )}
              <div className="result__graph">
                <Graph competences={arquetipo ? arquetipo.competences : null}/>
              </div>
            </div>
          </div>
          <div className="result-button__wrapper">
            <div className='question__button'>
              <div className='button'>
                <Link className='button__link' to={`/arquetipo?auth=${auth}`}>
                  Siguiente ‣
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}

export default Resultado
