import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { getToken } from '../Common'
import Admin from '../../Admin'

function AdminPrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        getToken() ? (
          <Admin
            component={Component}
            {...props}
            name={rest.name}
            classname={rest.classname}
          />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  )
}

export default AdminPrivateRoute
