import React from 'react'

const Textarea = (props) => {
  const { name, label, touched, valid, error, ...rest } = props

  let elementClass = 'form__textarea'
  if (touched && !valid) {
    elementClass = 'form__textarea form__error'
  }

  return (
    <div className='form__group'>
      {label && <label htmlFor={name}>{label}</label>}
      <textarea className={elementClass} name={name} {...rest} />
      {touched && !valid && (
        <div className='form__feedback'>
          <small style={{ color: 'red' }}>{error}</small>
          <br />
        </div>
      )}
    </div>
  )
}

export default Textarea
