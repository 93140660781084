import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import * as qs from 'query-string'
import Header from '../Common/Header'
import Footer from '../Common/Footer'
import './Welcome.css'

class Welcome extends Component {
  componentDidMount = () => {
    document.title = 'Seguridad Social - Bienvenid@'
    document.body.classList.add('welcome')
  }
  
  componentWillUnmount = () => {
    document.title = 'Seguridad Social'
    document.body.classList.remove('welcome')
  }

  render() {
    const auth = qs.parse(window.location.search).auth
    return (
      <>
        {/* Header Welcome */}
        <Header />
        {/* Container */}
        <div className='container'>
          <div className='welcome-content'>
            <div className='welcome-content__title'>
              <h1 className='title'>
                Empieza la cuenta atrás hacia tu revolución digital
              </h1>
            </div>
            <div className='welcome-content__text'>
              <p className='text'>El nuevo mundo es más digital que nunca.</p>
              <p className='text'>
                Un lugar donde la realidad paralela cobra mayor protagonismo.
              </p>
              <p className='text'>
                Donde lo único que no cambia es que todo cambia a gran
                velocidad.
              </p>
            </div>
            <div className='welcome-content__text-featured'>
              <p className='text-featured'>Y no podemos quedarnos atrás.</p>
            </div>
            <div className='welcome-content__text'>
              <p className='text'>
                Aquí empiezas un viaje para conocer tu punto de partida, el lugar donde comienza tu propia revolución digital.
              </p>
              <p className='text'>3, 2, 1…</p>
            </div>
          </div>
          <div className="button__wrapper">
            <div className='welcome-content__button'>
                <div className='button'>
                  <Link className='button__link' to={`/instrucciones?auth=${auth}`}>
                    ¡Despegamos! ‣
                  </Link>
                </div>
              </div>
          </div>
        </div>
        {/* Footer */}
        <Footer />
      </>
    )
  }
}

export default Welcome
