export const competences = [
  {
    value: 'ANÁLISIS Y TRATAMIENTO DE DATOS',
    label: 'Análisis y tratamiento de datos',
  },
  {
    value: 'RESOLUCIÓN DE PROBLEMAS',
    label: 'Resolución de problemas',
  },
  {
    value: 'COMUNICACIÓN Y COLABORACIÓN',
    label: 'Comunicación y colaboración',
  },
  {
    value: 'CREACIÓN DE CONTENIDOS DIGITALES',
    label: 'Creación de contenidos digitales',
  },
  {
    value: 'SEGURIDAD',
    label: 'Seguridad',
  },
]

export const answerTypes = [
  {
    value: 'UNIQUE',
    label: 'única',
  },
  {
    value: 'MULTIPLE',
    label: 'múltiple',
  },
]

export const points = [
  {
    value: 1,
    label: 1,
  },
  {
    value: 2,
    label: 2,
  },
  {
    value: 3,
    label: 3,
  },
  {
    value: 4,
    label: 4,
  },
]
