import React, { Component } from 'react'

class NotFound extends Component {
  render() {
    return (
      <div>
        {/* <h4>404 NotFound Page</h4>
        <p>This is NotFound page.</p> */}
      </div>
    )
  }
}

export default NotFound
