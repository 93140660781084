import React, { Component } from 'react'
import axios from 'axios'
import * as qs from 'query-string'
import Footer from '../Common/Footer'
import Header from '../Common/Header'
import './Game.css'

class Game extends Component {
  state = {
    response: {},
    answerType: null,
    questionId: null,
    answers: [],
    error: false,
    auth: qs.parse(window.location.search).auth,
  }

  formatImageUrl = (path) => {
    return `${process.env.REACT_APP_API_URL}/${
      path.split('public').reverse()[0]
    }`
  }

  componentDidMount = () => {
    document.title = 'Seguridad Social - Juego'
    document.body.classList.add('questions')
    this.resetClickedOnAnswerHTML()

    axios
      .get(`${process.env.REACT_APP_API_URL}/api/game/${this.state.auth}`)
      .then((response) => {
        if (response.data.finished === true) {
          this.props.history.push(`/resultado?auth=${this.state.auth}`)
        }

        let answerType = null
        if (response.data.answerType !== 'UNIQUE') {
          document.body.classList.add('questions-multiple')
          answerType = 'multi'
        }
        this.setState({
          answers: [],
          error: false,
          auth: qs.parse(window.location.search).auth,
          answerType,
          response: response.data,
          questionId: response.data ? response.data.id : null,
        })
        document.body.style.backgroundImage = response.data && response.data.backgroundImageUrl ? `url(${this.formatImageUrl(
          response.data.backgroundImageUrl
        )})` : null;

        return response
      })
      .then((response) => {
        const progressBarElement = document.getElementsByClassName(
          'progress-bar__status'
        )[0];
        if (progressBarElement) {
          progressBarElement.style.width = this.getProgressPercentage()
        } 
      })
      .catch((error) => {
        this.props.history.push('/')
      })
  }

  componentWillUnmount = () => {
    document.title = 'Seguridad Social'
    document.body.classList.remove('questions')
    document.body.classList.remove('questions-multiple')
  }

  getProgressPercentage = () => {
    const position = parseInt(this.state.response.position)
    const questionsNumber = parseInt(this.state.response.questionsNumber)
    const percentage = (position / questionsNumber) * 100

    return `${percentage}%`
  }

  handleAnswer = (e) => {
    const answerHTMLWrapper = e.target.closest('.answer')
    const answerId = answerHTMLWrapper.dataset.id
    let answers = null
    if (this.state.answerType !== null) {
      answers = this.state.answers
      if (!this.state.answers.includes(answerId)) {
        answerHTMLWrapper.classList.add('clicked')
        answers = answers.concat(answerId)
      } else {
        answerHTMLWrapper.classList.remove('clicked')
        answers = this.state.answers.filter((answer) => answer !== answerId)
      }
    } else {
      this.resetClickedOnAnswerHTML()
      answerHTMLWrapper.classList.add('clicked')
      answers = [answerId]
    }

    this.setState({ answers })
  }

  handleNextQuestion = () => {
    if (this.state.answers.length < 1) {
      this.setState({ error: true })
    } else {
      const data = {
        payload: {
          questionId: this.state.questionId,
          answers: this.state.answers,
        },
      }
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/results/${this.state.auth}`,
          data
        )
        .then((response) => {
          this.componentDidMount()
        })
        .catch((error) => {
          console.log('Error message', error.response.data.message)
          console.log('Error status', error.response.status)
          this.setState({
            error: true,
          })
        })
    }
  }

  resetClickedOnAnswerHTML() {
    const answersHTML = document.getElementsByClassName('answer')
    for (let elHTML of answersHTML) {
      elHTML.classList.remove('clicked')
    }
  }

  render() {
    return (
      <>
        <Header />
        <div className='container'>
          <div className='progress-bar'>
            <div className='progress-bar__status'>
              <div className='progress-bar__position'></div>
            </div>
          </div>
          <div className='questions-content'>
            <div className='question__content'>
              <div className='question__position'>
                <p className='question__number'>
                  {this.state.response.position}
                </p>
              </div>
              <h1 className='question__title'>{this.state.response.content}</h1>
              {this.state.answerType ? (
                <p className='question__text'>
                  Puedes elegir
                  <br />
                  varias respuestas.
                </p>
              ) : (
                <p className='question__text'>
                  Elije la respuesta que
                  <br />
                  mejor te identifique.
                </p>
              )}
            </div>
            {this.state.response.answers &&
              this.state.response.answers.map((answer) => {
                let answerClassName = 'answer'
                if (this.state.answerType) {
                  answerClassName += ' multi'
                }
                switch (answer.position) {
                  case 1:
                    answerClassName += ' answer__a'
                    break
                  case 2:
                    answerClassName += ' answer__b'
                    break
                  case 3:
                    answerClassName += ' answer__c'
                    break
                  case 4:
                    answerClassName += ' answer__d'
                    break
                  default:
                    answerClassName += ' answer__a'
                    break
                }

                return (
                  <div
                    data-id={answer.id}
                    key={answer.position}
                    className={answerClassName}
                    onClick={this.handleAnswer}
                  >
                    <div className='answer__choice'></div>
                    <p className='answer__text'>{answer.content}</p>
                  </div>
                )
              })}
          </div>
          <div className='questions-feedback'>
            {this.state.error && (
              <div className='question__feedback'>
                <p className='feedback__text'>
                  Selecciona al menos una respuesta.
                </p>
              </div>
            )}
            <div className='question__button'>
              <div className='button'>
                <button
                  className='button__link'
                  onClick={this.handleNextQuestion}
                >
                  Siguiente ‣
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}

export default Game
