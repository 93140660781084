import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { setUserSession } from './../../Utils/Common'
import './Login.css'
import logo from '../../../assets/img/logo-proyecta-tu-talento.svg'
import Input from './../../Admin/Common/FormComponents/Input'

class Login extends Component {
  state = {
    error: null,
    email: '',
    password: '',
  }

  componentDidMount = () => {
    document.body.classList.add('security')
    document.title = 'Seguridad Social - Login'
  }

  componentWillUnmount = () => {
    document.title = 'Seguridad Social'
    document.body.classList.remove('security')
  }

  handleLogin = (e) => {
    e.preventDefault()
    const data = {
      username: this.state.email,
      password: this.state.password,
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/api/login_check`, data)
      .then((response) => {
        setUserSession(
          response.data.token,
          this.state.email,
          response.data.data
        )
        this.props.history.push('/admin/dashboard')
      })
      .catch((error) => {
        console.log(error)
        if (error.response.status === 401) {
          this.setState({ error: 'Credenciales inválidos.' })
        } else {
          this.setState({
            error: 'Algo malo ha ocurrido. Inténtalo más tarde.',
          })
        }
      })
  }

  onChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    this.setState({ [name]: value })
  }

  render() {
    return (
      <form className='login-form' action='#' method='POST' noValidate>
        <div className='login-form__logo-container'>
          <img className='login-form__logo' src={logo} alt='talento-digital' />
        </div>
        <div className='login-form__content'>
          <div className='login-form__header'>Identifícate</div>
          <Input
            className='login-form__input'
            type='text'
            name='email'
            placeholder='Email'
            autoFocus
            value={this.state.email}
            onChange={(e) => this.onChange(e)}
          />
          <Input
            className='login-form__input'
            type='password'
            name='password'
            placeholder='Password'
            value={this.state.password}
            onChange={(e) => this.onChange(e)}
          />
          <button
            className='login-form__button button__link'
            type='submit'
            onClick={this.handleLogin}
          >
            Login ‣
          </button>
          {this.state.error && (
            <div className='login-form__feedback'>
              <small style={{ color: 'red' }}>{this.state.error}</small>
              <br />
            </div>
          )}
          <br />
          <div className='login-form__links'>
            <Link className='login-form__link' to='/forgot-password'>
              Olvidaste tu contraseña?
            </Link>
          </div>
        </div>
      </form>
    )
  }
}

export default Login
