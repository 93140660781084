import axios from 'axios'
import { getToken } from '../../../Utils/Common'

const uploadImage = (file, filename, url, jwt = false) => {
  let config = {}
  if (jwt) {
    config = {
      headers: { Authorization: `Bearer ${getToken()}` },
    }
  }
  const formData = new FormData()
  formData.append('file', file, filename)

  return new Promise((resolve, reject) => {
    return axios
      .post(url, formData, config)
      .then((response) => resolve(response.data.filePath))
      .catch((error) => reject(error))
  })
}

export default uploadImage
