import React from 'react'
import { Link } from 'react-router-dom'
import './SidebarList.css'

const handleActive = (e) => {
  const activeEl = e.target.closest('.menu__item')
  resetActiveClass()
  activeEl.classList.add('active')
  return null
}

const resetActiveClass = () => {
  const listItems = document.getElementsByClassName('menu__item')
  Object.keys(listItems).map((key) => {
    listItems[key].classList.remove('active')

    return null
  })
}

export default function mainListItems(props) {
  let questionsClassName = 'menu__item'
  let dashboardClassName = 'menu__item'
  const pathname = props.history.location.pathname.split('/admin')[1]
  if (pathname.includes('/profile')) {
    resetActiveClass()
  }
  if (pathname.includes('/dashboard')) {
    dashboardClassName = 'menu__item active'
  }
  if (pathname.includes('/questions')) {
    questionsClassName = 'menu__item active'
  }
  return (
    <ul className='menu__list'>
      <li className={dashboardClassName} onClick={handleActive}>
        <Link to='/admin/dashboard'>
          <i className='fas fa-tachometer-alt'></i> Dashboard
        </Link>
      </li>
      <li className={questionsClassName} onClick={handleActive}>
        <Link to='/admin/questions'>
          <i className='fas fa-question'></i> Preguntas
        </Link>
      </li>
    </ul>
  )
}
