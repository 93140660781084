import React, { Component } from 'react'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { getToken } from '../../../Utils/Common'
import './Form.css'
import { competences, answerTypes, points } from './enum'
import Input from '../../Common/FormComponents/Input'
import Select from '../../Common/FormComponents/Select'
import validate from '../../Common/FormComponents/Validation'
import uploadImage from '../../Common/FormComponents/UploadImage'
import Textarea from '../../Common/FormComponents/Textarea'

class QuestionForm extends Component {
  state = {
    formIsValid: false,
    payload: {
      backgroundImageUrl: '',
      id: this.props.match.params.id ?? uuidv4(),
      answers: [
        {
          id: uuidv4(),
          position: 1,
        },
        {
          id: uuidv4(),
          position: 2,
        },
        {
          id: uuidv4(),
          position: 3,
        },
        {
          id: uuidv4(),
          position: 4,
        },
      ],
    },
    formControls: {
      position: {
        value: '',
        placeholder: 'Indica la posición de la pregunta',
        valid: false,
        touched: false,
        validationRules: {
          minValue: 1,
          maxValue: 36,
        },
        error: 'Debes indicar un número entre 1 y 36.',
      },
      competence: {
        value: '',
        placeholder: 'Selecciona una competencia',
        valid: false,
        touched: false,
        validationRules: {
          required: true,
          enum: Object.keys(competences).map(
            (competence) => competences[competence].value
          ),
        },
        error: 'Debes seleccionar al menos un elemento.',
      },
      content: {
        value: '',
        placeholder: 'Introduce el contenido de la pregunta',
        valid: false,
        touched: false,
        validationRules: {
          required: true,
        },
        error: 'El contenido es requerido.',
      },
      answerType: {
        value: '',
        placeholder: 'Selecciona un tipo de respuesta',
        valid: false,
        touched: false,
        validationRules: {
          required: true,
          enum: Object.keys(answerTypes).map(
            (answerType) => answerTypes[answerType].value
          ),
        },
        error: 'Debes seleccionar al menos un elemento.',
      },
      backgroundImageUrl: {
        value: '',
        placeholder:
          'Introduce una imagen de fondo de pantalla para la pregunta',
        valid: false,
        touched: false,
        validationRules: {
          required: true,
        },
        error: 'La imagen de fondo es requerida.',
      },
      answerPoints_1: {
        value: '',
        placeholder: 'Indica la puntuación de la respuesta 1',
        valid: false,
        touched: false,
        validationRules: {
          minValue: 1,
          maxValue: 4,
        },
        error: 'Debes indicar un número entre 1 y 4.',
      },
      answerContent_1: {
        value: '',
        placeholder: 'Indica el contenido de la respuesta 1',
        valid: false,
        touched: false,
        validationRules: {
          required: true,
        },
        error: 'El campo es requerido.',
      },
      answerPoints_2: {
        value: '',
        placeholder: 'Indica la puntuación de la respuesta 2',
        valid: false,
        touched: false,
        validationRules: {
          minValue: 1,
          maxValue: 4,
        },
        error: 'Debes indicar un número entre 1 y 4.',
      },
      answerContent_2: {
        value: '',
        placeholder: 'Indica el contenido de la respuesta 2',
        valid: false,
        touched: false,
        validationRules: {
          required: true,
        },
        error: 'El campo es requerido.',
      },
      answerPoints_3: {
        value: '',
        placeholder: 'Indica la puntuación de la respuesta 3',
        valid: false,
        touched: false,
        validationRules: {
          minValue: 1,
          maxValue: 4,
        },
        error: 'Debes indicar un número entre 1 y 4.',
      },
      answerContent_3: {
        value: '',
        placeholder: 'Indica el contenido de la respuesta 3',
        valid: false,
        touched: false,
        validationRules: {
          required: true,
        },
        error: 'El campo es requerido.',
      },
      answerPoints_4: {
        value: '',
        placeholder: 'Indica la puntuación de la respuesta 4',
        valid: false,
        touched: false,
        validationRules: {
          minValue: 1,
          maxValue: 4,
        },
        error: 'Debes indicar un número entre 1 y 4.',
      },
      answerContent_4: {
        value: '',
        placeholder: 'Indica el contenido de la respuesta 4',
        valid: false,
        touched: false,
        validationRules: {
          required: true,
        },
        error: 'El campo es requerido.',
      },
    },
  }

  onChangeFile = (e) => {
    const name = e.target.name
    const file = e.target.files[0]
    if (file) {
      this.onChange(e)
      uploadImage(
        file,
        file.name,
        `${process.env.REACT_APP_API_URL}/api/questions/upload`,
        true
      )
        .then((filepath) => {
          const payload = { ...this.state.payload }
          const formControls = { ...this.state.formControls }
          payload[name] = filepath
          formControls[name].value = filepath
          formControls[name].valid = true
          formControls[name].touched = true
          this.setState({ payload, formControls })
        })
        .catch((error) => {
          error.response && error.response.data
            ? console.log(error.response.data.message)
            : console.log('error', error)
        })
    }
  }

  onChange = (e) => {
    const name = e.target.name
    const value = e.target.value

    const updatedControls = { ...this.state.formControls }
    const updatedFormElement = { ...updatedControls[name] }
    updatedFormElement.value = value
    updatedFormElement.touched = true
    updatedFormElement.valid = validate(
      value,
      updatedFormElement.validationRules
    )

    updatedControls[name] = updatedFormElement

    let formIsValid = true
    for (let inputIdentifier in updatedControls) {
      formIsValid = updatedControls[inputIdentifier].valid && formIsValid
    }

    this.setState({
      formControls: updatedControls,
      formIsValid,
    })
  }

  formatImageUrl = (path) => {
    return path.split('public').reverse()[0]
  }

  onSubmit = (e) => {
    e.preventDefault()
    if (!this.state.formIsValid) {
      return
    }

    const config = {
      headers: { Authorization: `Bearer ${getToken()}` },
    }

    const payload = { ...this.state.payload }
    const formControls = { ...this.state.formControls }
    Object.keys(formControls).map((key) => {
      const keySplitted = key.split('_')
      if (keySplitted.includes('answerContent')) {
        payload.answers[Number(keySplitted[1]) - 1].content =
          formControls[key].value
      }
      if (keySplitted.includes('answerPoints')) {
        payload.answers[Number(keySplitted[1]) - 1].points = parseInt(
          formControls[key].value,
          10
        )
      }
      if (key === 'competence' || key === 'answerType' || key === 'content') {
        payload[key] = formControls[key].value
      }
      if (key === 'position') {
        payload[key] = parseInt(formControls[key].value, 10)
      }

      return null
    })

    axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/questions/${payload.id}`,
        { payload },
        config
      )
      .then((response) => {
        this.props.history.push(`/admin/questions`)
      })
      .catch((error) => {
        error.response && error.response.data
          ? console.log(error.response.data.message)
          : console.log('error', error)
      })
  }

  componentDidMount() {
    const config = {
      headers: { Authorization: `Bearer ${getToken()}` },
    }
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/questions/${this.state.payload.id}`,
        config
      )
      .then((response) => {
        if (response.data) {
          const {
            id,
            position,
            competence,
            content,
            answerType,
            backgroundImageUrl,
            answers,
          } = response.data

          const payload = { ...this.state.payload }
          payload.id = id
          answers.map((answer, i) => {
            payload.answers[i].id = answer.id
            return null
          })
          payload.backgroundImageUrl = backgroundImageUrl

          const formControls = { ...this.state.formControls }
          formControls.position.value = position
          formControls.position.valid = true
          formControls.position.touched = true
          formControls.competence.value = competence
          formControls.competence.valid = true
          formControls.competence.touched = true
          formControls.content.value = content.trim()
          formControls.content.valid = true
          formControls.content.touched = true
          formControls.answerType.value = answerType
          formControls.answerType.valid = answerType
          formControls.answerType.touched = answerType
          formControls.backgroundImageUrl.value = this.formatImageUrl(
            backgroundImageUrl
          )
          console.log(this.formatImageUrl(backgroundImageUrl))
          formControls.backgroundImageUrl.valid = true
          formControls.backgroundImageUrl.toueched = true
          answers.map((answer, i) => {
            formControls[`answerPoints_${i + 1}`].value = answer.points
            formControls[`answerPoints_${i + 1}`].valid = true
            formControls[`answerPoints_${i + 1}`].touched = true
            formControls[`answerContent_${i + 1}`].value = answer.content.trim()
            formControls[`answerContent_${i + 1}`].valid = true
            formControls[`answerContent_${i + 1}`].touched = true
            return null
          })

          this.setState({ payload, formControls, formIsValid: true })

          return response.data
        }

        return null
      })
      .catch((error) => {
        error.response
          ? console.log(error.response.data.message)
          : console.log('error', error)
      })
  }

  render() {
    return (
      <section className='dashboard-form'>
        <div className='form-header'>
          <div className='icon-section'>
            <i className='fas fa-question'></i>
          </div>
          <div className='title-section'>
            <p className='title-section__title'>
              Pregunta Nº
              {this.state.formControls.position.value &&
              this.state.formControls.position.valid
                ? this.state.formControls.position.value
                : '??'}
            </p>
            <small className='title-section__description'>
              Completa el formulario
            </small>
          </div>
        </div>

        <form className='question-form' noValidate onSubmit={this.onSubmit}>
          <div className='question-form__content'>
            {/* Position */}
            <Input
              label='Posición'
              name='position'
              type='number'
              placeholder={this.state.formControls.position.placeholder}
              value={this.state.formControls.position.value}
              onChange={this.onChange}
              touched={this.state.formControls.position.touched}
              valid={this.state.formControls.position.valid}
              error={this.state.formControls.position.error}
            />
            {/* Competence */}
            <Select
              label='Competencia'
              name='competence'
              options={competences}
              defaultValue={this.state.formControls.competence.placeholder}
              value={this.state.formControls.competence.value}
              onChange={this.onChange}
              touched={this.state.formControls.competence.touched}
              valid={this.state.formControls.competence.valid}
              error={this.state.formControls.competence.error}
            />
            {/* Content */}
            <Textarea
              label='Contenido'
              name='content'
              placeholder={this.state.formControls.content.placeholder}
              value={this.state.formControls.content.value}
              onChange={this.onChange}
              touched={this.state.formControls.content.touched}
              valid={this.state.formControls.content.valid}
              error={this.state.formControls.content.error}
            />
            {/* AnswerType */}
            <Select
              label='Tipo de respuesta'
              name='answerType'
              options={answerTypes}
              defaultValue={this.state.formControls.answerType.placeholder}
              value={this.state.formControls.answerType.value}
              onChange={this.onChange}
              touched={this.state.formControls.answerType.touched}
              valid={this.state.formControls.answerType.valid}
              error={this.state.formControls.answerType.error}
            />
            {/* BackgroundImageUrl */}
            <Input
              label='Imagen de fondo'
              name='backgroundImageUrl'
              type='file'
              placeholder={
                this.state.formControls.backgroundImageUrl.placeholder
              }
              onChange={this.onChangeFile}
              touched={this.state.formControls.backgroundImageUrl.touched}
              valid={this.state.formControls.backgroundImageUrl.valid}
              error={this.state.formControls.backgroundImageUrl.error}
            />
            {this.state.formControls.backgroundImageUrl.value && (
              <img
                className={'img-fluid'}
                src={`${process.env.REACT_APP_API_URL}/${this.state.formControls.backgroundImageUrl.value}`}
                alt='question-background'
              />
            )}
            <hr className='separator__answers' />
            {/* Respuestas */}
            <div className='form__answers__wrapper'>
              {/* Respuesta 1 */}
              <p className='form__answer__wrapper__title'>Respuesta 1</p>
              {/* Puntuación Respuesta 1 */}
              <Select
                label='Puntuación'
                name='answerPoints_1'
                options={points}
                defaultValue={
                  this.state.formControls.answerPoints_1.placeholder
                }
                value={this.state.formControls.answerPoints_1.value}
                onChange={this.onChange}
                touched={this.state.formControls.answerPoints_1.touched}
                valid={this.state.formControls.answerPoints_1.valid}
                error={this.state.formControls.answerPoints_1.error}
              />
              {/* Contenido Respuesta 1 */}
              <Textarea
                label='Contenido'
                name='answerContent_1'
                placeholder={
                  this.state.formControls.answerContent_1.placeholder
                }
                value={this.state.formControls.answerContent_1.value}
                onChange={this.onChange}
                touched={this.state.formControls.answerContent_1.touched}
                valid={this.state.formControls.answerContent_1.valid}
                error={this.state.formControls.answerContent_1.error}
              />
              {/* Respuesta 2 */}
              <p className='form__answer__wrapper__title'>Respuesta 2</p>
              {/* Puntuación Respuesta 2 */}
              <Select
                label='Puntuación'
                name='answerPoints_2'
                options={points}
                defaultValue={
                  this.state.formControls.answerPoints_2.placeholder
                }
                value={this.state.formControls.answerPoints_2.value}
                onChange={this.onChange}
                touched={this.state.formControls.answerPoints_2.touched}
                valid={this.state.formControls.answerPoints_2.valid}
                error={this.state.formControls.answerPoints_2.error}
              />
              {/* Contenido Respuesta 2 */}
              <Textarea
                label='Contenido'
                name='answerContent_2'
                placeholder={
                  this.state.formControls.answerContent_2.placeholder
                }
                value={this.state.formControls.answerContent_2.value}
                onChange={this.onChange}
                touched={this.state.formControls.answerContent_2.touched}
                valid={this.state.formControls.answerContent_2.valid}
                error={this.state.formControls.answerContent_2.error}
              />
              {/* Respuesta 3 */}
              <p className='form__answer__wrapper__title'>Respuesta 3</p>
              {/* Puntuación Respuesta 3 */}
              <Select
                label='Puntuación'
                name='answerPoints_3'
                options={points}
                defaultValue={
                  this.state.formControls.answerPoints_3.placeholder
                }
                value={this.state.formControls.answerPoints_3.value}
                onChange={this.onChange}
                touched={this.state.formControls.answerPoints_3.touched}
                valid={this.state.formControls.answerPoints_3.valid}
                error={this.state.formControls.answerPoints_3.error}
              />
              {/* Contenido Respuesta 3 */}
              <Textarea
                label='Contenido'
                name='answerContent_3'
                placeholder={
                  this.state.formControls.answerContent_3.placeholder
                }
                value={this.state.formControls.answerContent_3.value}
                onChange={this.onChange}
                touched={this.state.formControls.answerContent_3.touched}
                valid={this.state.formControls.answerContent_3.valid}
                error={this.state.formControls.answerContent_3.error}
              />
              {/* Respuesta 4 */}
              <p className='form__answer__wrapper__title'>Respuesta 4</p>
              {/* Puntuación Respuesta 4 */}
              <Select
                label='Puntuación'
                name='answerPoints_4'
                options={points}
                defaultValue={
                  this.state.formControls.answerPoints_4.placeholder
                }
                value={this.state.formControls.answerPoints_4.value}
                onChange={this.onChange}
                touched={this.state.formControls.answerPoints_4.touched}
                valid={this.state.formControls.answerPoints_4.valid}
                error={this.state.formControls.answerPoints_4.error}
              />
              {/* Contenido Respuesta 4 */}
              <Textarea
                label='Contenido'
                name='answerContent_4'
                placeholder={
                  this.state.formControls.answerContent_4.placeholder
                }
                value={this.state.formControls.answerContent_4.value}
                onChange={this.onChange}
                touched={this.state.formControls.answerContent_4.touched}
                valid={this.state.formControls.answerContent_4.valid}
                error={this.state.formControls.answerContent_4.error}
              />
            </div>
            {/* Submit */}
            <button
              className='form__button button__link'
              type='submit'
              onClick={this.onSubmit}
              disabled={!this.state.formIsValid}
            >
              Enviar ‣
            </button>
          </div>
        </form>
      </section>
    )
  }
}

export default QuestionForm
