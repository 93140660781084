import React from 'react'
import './Graph.css'
import { levelCompetences, levelCompetencesData } from '../levelCompetences'

const calculatePercentage = (value) => {
  let maxValue = levelCompetencesData[levelCompetences[levelCompetences.length - 1]].max;
  let minValue = levelCompetencesData[levelCompetences[0]].min;
  const newValue = value - minValue;
  const total = maxValue - minValue;

  return (newValue * 100 / total).toFixed(0);
}

const calculatePercentageCompetence = (value) => {
  let maxValue = levelCompetencesData[levelCompetences[levelCompetences.length - 1]].max;
  let minValue = levelCompetencesData[levelCompetences[0]].min;
  let numCompetences = levelCompetences.length - 1;
  const total = maxValue - minValue - numCompetences;

  return (value * 100 / total).toFixed(0);
}

const formattedString = (string) => string.toLowerCase().replace(' ', '_');

const getIndex = (value) => value.toString().padStart(2, '0');

const Graph = ({ competences }) => {
  return(
    <div className="graph">
      {/* Background Graph */}
      {Object.keys(levelCompetencesData).map(competence => {
        const competenceData = levelCompetencesData[competence];
        const value = calculatePercentageCompetence(competenceData.max - competenceData.min);
        return (<div key={formattedString(competence)} className={formattedString(competence)} style={{ height: `${value}%` }}></div>)
      })}
      {/* Vertical Bar Chart */}
      <div className="bar_chart__container">
        {competences && Object.keys(competences).map((competence, index) => {
          const competenceData = competences[competence];
          const value = calculatePercentage(competenceData.points);
          return (
            <div key={formattedString(competence)}>
              <div className="nobar" style={{ height: `${100 - value}%` }}></div>
              <div className={`bar ${formattedString(competence)}`} style={{ height: `${value}%` }}></div>
              <div className={`number ${index + 1}`}><p>{getIndex(index + 1)}</p></div>
            </div>
          )
        })}
      </div>
      {/* Notes */}
      <div className="notes_chart__container">
        {Object.keys(levelCompetencesData).map(competence => {
          return (
            <div key={formattedString(competence)} className="notes_chart">
              <span className={`notes_chart_color ${formattedString(competence)}`}></span>
              <span className="notes_chart__name">{competence}</span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Graph
