import React from 'react'
import Navbar from './Navbar'

function MainContent({ component: Component, ...props }) {
  return (
    <div className='content'>
      <Navbar {...props} />
      <Component {...props} />
    </div>
  )
}

export default MainContent
