import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import * as qs from 'query-string'
import logo_footer from '../../../../assets/img/logo-seguridad-social-digital.svg'
import './Footer.css'

class Footer extends Component {
  render() {
    const auth = qs.parse(window.location.search).auth
    return (
      <footer className='footer'>
        <div className='footer-item footer__links'>
          <div className='footer__copyright'>
            <p><span>© GISS</span> · Todos los derechos reservados</p>
          </div>
          <div className='footer__legal'>
            <Link to={`/aviso-legal?auth=${auth}`}>Aviso legal</Link>
          </div>
        </div>
        <div className='footer-item footer__logo'>
          <img
            src={logo_footer}
            alt='gerencia-de-informatica-de-la-seguridad-social'
          />
        </div>
      </footer>
    )
  }
}

export default Footer
